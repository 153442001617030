export enum EstimateStatus {
  DRAFT = "DRAFT",
  APPROVAL_PENDING = "APPROVAL_PENDING",
  APPROVED = "APPROVED",
  CANCELLED = "CANCELLED",
  EMAIL_SENT = "EMAIL_SENT",
  INVOICED = "INVOICED",
}

export enum InvoiceStatus {
  DRAFT = "DRAFT",
  APPROVAL_PENDING = "APPROVAL_PENDING",
  APPROVED = "APPROVED",
  CANCELLED = "CANCELLED",
  EMAIL_SENT = "EMAIL_SENT",
  PARTIALLY_PAID = "PARTIALLY_PAID",
  PAID = "PAID",
}

export enum ProformaInvoiceStatus {
  CREATED = "CREATED",
  CONVERTED = "CONVERTED",
  CANCELLED = "CANCELLED",

}