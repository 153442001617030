import {
    ApprovalOutlined,
    CancelOutlined,
    DownloadOutlined,
    EditOutlined,
    MailOutline,
    Payment,
    PreviewOutlined,
} from "@mui/icons-material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { Menu, MenuItem } from "@mui/material";
import {
    downloadInvoice,
    submitInvoiceForApproval,
} from "api/services/billing/invoices";
import { snack } from "components/toast";
import { useContext, useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
    handleClientChange,
} from "redux/reducers/createInvoiceSlice";
import { handleError } from "utils/handleError";
import { StyledActionsMenu } from "../styles";
import { InvoiceStatus, ProformaInvoiceStatus } from "../types";
import ViewApprovalStatus from "../invoices/ViewApprovalStatus";
import ValidateAccess from "components/ValidateAccess";
import { Permissions } from "data/permissons";
import { useConfirm } from "context/ConfirmDialog";
import { placeOfSupplyStates } from "data/placeOfSupply";
import { UserProfileContext } from "context/UserProfile";
import { cancelProformaInvoice, downloadProformaInvoice } from "api/services/billing/proforma-invoice";
interface Props {
    anchorEl: HTMLElement | null;
    setAnchorEl: (anchorEl: HTMLElement | null) => void;
    selected: any[];
    clearSelection: () => void;
}

const Actions = (props: Props) => {
    const { anchorEl, setAnchorEl, selected, clearSelection } = props;
    const dispatch = useDispatch();
    const confirm = useConfirm();
    const { data: uData } = useContext(UserProfileContext);
    const queryClient = useQueryClient();
    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const { mutate } = useMutation(downloadProformaInvoice, {
        onSuccess: (res: any) => {
            setIsLoading(false)
            const arr = new Uint8Array(res.data?.data);
            const blob = new Blob([arr], { type: "application/pdf" });
            const pdf = window.URL.createObjectURL(blob);
            let link = document.createElement("a");
            link.href = pdf;
            link.download = `${(selected[0]?.client ? selected[0]?.client?.tradeName : selected[0]?.clientGroup?.tradeName) || selected[0]?.client ? selected[0]?.client?.displayName : selected[0]?.clientGroup?.displayName} - ${selected[0]?.invoiceNumber}.pdf`;
            link.click();
        },
        onError: (err: any) => {
            setIsLoading(false)
            snack.error(handleError(err));
        },
    });

    const handlePriview = () => {
        dispatch(handleClientChange({ client: selected[0].id }))
        window.open(`/billing/proforma/${selected[0]?.id}/preview`);
    };

    const handleDownload = () => {
        setIsLoading(true)
        mutate({ id: selected[0]?.id, userId: uData.id });
    };
    console.log(Boolean(anchorEl),anchorEl)
    return (
        <>
            <StyledActionsMenu
                anchorEl={anchorEl}
                onClick={() => setAnchorEl(null)}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
            >
                {/* <ValidateAccess name={Permissions.PREVIEW_PROFORMA_INVOICE}> */}


                    <MenuItem
                        disabled={
                            selected.length !== 1 ||
                            selected[0]?.status === InvoiceStatus.CANCELLED
                        }
                        onClick={() => handlePriview()}
                        key="cancelled"
                    >
                        <PreviewOutlined />
                        Preview Proforma Invoice
                    </MenuItem>
                {/* </ValidateAccess>
                <ValidateAccess name={Permissions.DOWNLOAD_PROFORMA_INVOICE}> */}

                    <MenuItem
                        onClick={handleDownload}
                        disabled={
                            selected.length !== 1 ||
                            selected[0]?.status === InvoiceStatus.CANCELLED ||
                            isLoading
                        }
                        key="downlaod"
                    >
                        <DownloadOutlined />
                        Download Proforma Invoice
                    </MenuItem>
                {/* </ValidateAccess> */}
            </StyledActionsMenu>
        </>
    );
};

export default Actions