import { http } from "api/http";


const getProformaInvoices = ({ queryKey }) => {
    return http.get("/proforma", { params: { ...queryKey[1] } });
};

const createProformaInvoice = ({ data }) => {
    return http.post("/proforma", data);
};

const getProformaInvoicePreview = ({ queryKey }) => {
    const { invoiceId, orgId } = queryKey[1]
    return http.get(`/proforma/${invoiceId}/preview`, { params: { orgId: orgId } });
};

const updateProformaInvoice = ({ data }) => {
    return http.put(`/proforma/${data?.id}`, data);
};

const getProformaTasks = ({ queryKey }) => {
    return http.get("/proforma/performa-tasks", {
        params: { ...queryKey[1] },
    });
};

const downloadProformaInvoice = ({ id, userId }) => {
    return http.post(`/proforma/${id}/download`, { userId: userId });
};


const convertProformaInvoice = ({ data }) => {
    return http.post("/proforma/convert", data);
};

const cancelProformaInvoice = ({ id }) => {
    return http.post(`/proforma/${id}/cancel`);
};

const exportProformaInvoices = ({ data }) => {
    return http.post(`/proforma/export`, data);
};


const downloadProfomaInvoicewithoutEmittor = ({ id }) => {
    return http.post(`/proforma/${id}/downloadwithoutemittor`);
};

export {
    getProformaInvoices,
    createProformaInvoice,
    getProformaInvoicePreview,
    updateProformaInvoice,
    getProformaTasks,
    downloadProformaInvoice,
    convertProformaInvoice,
    cancelProformaInvoice,
    exportProformaInvoices,
    downloadProfomaInvoicewithoutEmittor
}