import { http } from "api/http";

const getInvoices = ({ queryKey }) => {
  return http.get(`/invoices/client-panel/${queryKey[1].clientId}`, { params: { ...queryKey[1] } });
};

const getClientInvoices = ({ queryKey }) => {
  return http.get(`/invoices/${queryKey[1]}`);
};

const getClientBillingInvoices = ({ queryKey }) => {
  return http.get(`/invoices/client-bill`, { params: { ...queryKey[1] } });
}

const getInvoice = ({ queryKey }) => {
  return http.get(`/invoices/${queryKey[1]}`);
};

const getInvoicePreview = ({ queryKey }) => {
  const { invoiceId, orgId, clientId } = queryKey[1]
  return http.get(`/invoices/${invoiceId}/preview`, { params: { orgId: orgId, clientId: clientId } });
};

const downloadInvoice = ({ id, userId  }) => {
  return http.post(`/invoices/${id}/download`, { userId: userId });
};

const downloadInvoicewithoutEmittor = ({ id }) => {
  return http.post(`/invoices/${id}/downloadwithoutemittor`);
};

const exportInvoices = ({ data }) => {
  return http.post(`/invoices/export`, data);
};

const submitInvoiceForApproval = ({ id }) => {
  return http.post(`/invoices/${id}/submit-for-approval`);
};

const exportGstBilling = (data:any) =>{
  return http.post("/invoices/gst-billing-export", data);
}

const exportTdsBilling = ( data:any) => {
  return http.post("/invoices/tds-export", data);
};

export {
  getInvoices,
  getInvoicePreview,
  getClientInvoices,
  downloadInvoice,
  exportInvoices,
  downloadInvoicewithoutEmittor,
  getClientBillingInvoices,
  submitInvoiceForApproval,
  exportGstBilling,
  exportTdsBilling
};