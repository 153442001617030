import { Box, Button, Typography } from "@mui/material";
import { downloadInvoicewithoutEmittor } from "api/services/billing/invoices";
import Table from "components/Table";
import useTitle from "hooks/useTitle";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { ResType } from "types";
import { getTitle } from "utils";
import { formattedDate } from "utils/formattedDate";
import { getStatusColor } from "views/billing/invoices/getStatusColor";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { resetFilters } from "redux/reducers/taskboardSlice";
import { snack } from "components/toast";
import PageWithPermission from "components/PageWithPermission";
import { Permissions } from "data/permissons";
import moment from "moment";
import { formattedAmount } from "utils/formattedAmount";
import { useConfirm } from "context/ConfirmDialog";
import { invoiceReminder } from "api/services/common";
import ProformaHeader from "views/billing/proforma/proformaHeader";
import { SortDirection } from "data/constants";
import { getProformaInvoices } from "api/services/billing/proforma-invoice";

const Proforma = () => {
    useTitle("Billing | Proforma Invoice");
    const confirm = useConfirm();
    const clientId = localStorage.getItem('clientId');
    const navigate = useNavigate();
    const [search, setSearch] = useState("");
    const [page, setPage] = useState<number>(0);
    const [pageCount, setPageCount] = useState<number>(10);
    const [selected, setSelected] = useState<any[]>([]);
    const [filters, setFilters] = useState<any>({ status: "", billingEntity: [] });
    const [sort, setSort] = useState<any>({});
    const [columns, setColumns] = useState(column);
    const dispatch = useDispatch();
    const [dates, setDates] = useState({ fromDate: null, toDate: null });

    useEffect(() => {
        return () => {
            dispatch(resetFilters());
        };
    }, []);

    const { data, isLoading }: ResType = useQuery(
        ["proforma-invoices", {
            offset: page * pageCount,
            limit: pageCount,
            search,
            status: filters.status,
            billingEntity: filters.billingEntity,
            ...dates,
            sort,
            clientId: clientId
        }],
        getProformaInvoices, {
        onError: (err) => {
            if (!err?.error) {
                snack.error(`An error occurred while fetching proforma-invoices`);
            }
        }
    }
    );

    const handleRowClick = (v: any) => {
        window.open(`/billing/proforma/${v?.id}/preview`);
    };

    const RemindButton = ({ row }: { row: any }) => {
        const handleRemindClick = (event) => {
            event.stopPropagation();
            handleRemind(row);
        };

        return (
            <Button onClick={handleRemindClick}>Remind</Button>
        );
    };


    const handleRemind = (row: any) => {
        const id = row.id
        confirm({
            msg: `Are you sure you want to send a reminder to the client through whatsapp?`,
            action: async () => {
                const pdf: any = await downloadInvoicewithoutEmittor({ id });
                const data = {
                    invoiceData: row,
                    invoiceBuffer: pdf?.data?.data
                };
                await invoiceReminder(data);
            },
        });
    };


    const setSearchChange = (e) => {
        setSearch(e);
        setPage(0);
    };


    const handleDates = (value) => {
        setDates(value);
        setPage(0);
    }


    const totalCount = data?.data?.totalCount || 0;

    return (
        <Box p={3} pl={1} pr={1} pb={0}>

                <ProformaHeader
                    clearSelection={() => setSelected([])}
                    selected={selected}
                    search={search}
                    setSearch={setSearchChange}
                    filters={filters}
                    setFilters={setFilters}
                    dates={dates}
                    setDates={handleDates}
                    sort={sort}
                    setPage={setPage}
                />
                <Table
                    sx={{ height: 'calc(75vh - 10px)', mb: 0, pb: 0 }}
                    selection={{ selected, setSelected }}
                    pagination={{ totalCount, pageCount, setPageCount, page, setPage }}
                    data={data?.data?.result || []}
                    columns={columns}
                    loading={isLoading}
                    onRowClick={handleRowClick}
                />
        </Box>
    );
};



export default Proforma;

let column: any = [
    {
        key: "invoiceNumber",
        title: "Invoice #",
    },
    {
        key: "invoiceDate",
        title: "Invoice Date",
        sort: true,
        active: false,
        direction: SortDirection.ASC,
        render: (row: any) => formattedDate(row.invoiceDate),

    },
    {
        key: "billingEntity.tradeName",
        title: "Billing Entity",
        sort: true,
        active: false,
        direction: SortDirection.ASC,
        width: "20%",
    },
    {
        key: "client.displayName",
        title: "Client / client Group",
        sort: true,
        active: false,
        direction: SortDirection.ASC,
        render: (row: any) => row.client ? <Typography>{row?.client?.displayName}</Typography> : <Typography>{row?.clientGroup?.displayName}<img src={""} width={"22px"} style={{ marginLeft: "10px", marginRight: "10px" }} alt="group" /></Typography>,
    },
    {
        key: "grandTotal",
        title: "Invoice Amount",
        sort: true,
        active: false,
        direction: SortDirection.ASC,
        render: (row: any) => formattedAmount(row.grandTotal)
    },

    {
        key: "invoiceDueDate",
        title: "Due Date",
        sort: true,
        active: false,
        direction: SortDirection.ASC,
        render: (row: any) => formattedDate(row.invoiceDueDate),

    },
    {
        key: "status",
        title: "Status",
        sort: true,
        active: false,
        direction: SortDirection.ASC,
        render: (row: any) => (
            <Box
                sx={{
                    background: getStatusColor(((row?.status == 'APPROVAL_PENDING' || row?.status == 'PARTIALLY_PAID') && (row?.invoiceDueDate <= moment().subtract(1, "day").format("YYYY-MM-DD"))) ? "OVERDUE" : row?.status),
                    px: 2,
                    py: "4px",
                    color: "white",
                    borderRadius: "10px",
                    textAlign: "center",
                    display: "inline-flex",
                }}
            >
                <Typography variant="body2">
                    {getTitle(row?.status?.toLowerCase())}
                </Typography>
            </Box>
        ),
    },
    // {
    //     key: 'remind',
    //     title: "Reminder",
    //     render: (row: any) => (
    //         row.status !== 'PAID' && row.status !== 'CANCELLED' && (
    //             <RemindButton row={row} />
    //         )
    //     ),
    // },
];
