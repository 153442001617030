import { http } from "api/http";

const getBillingEntity = ({ queryKey }: any) => {
  return http.get("/billing-entities", { params: { ...queryKey[1] } });
};

const getActiveBillingEntity = () => {
  return http.get("/billing-entities/active");
};

const getBillingEntityDetails = ({ queryKey }: any) => {
  return http.get(`/billing-entities/${queryKey[1]}`);
};

export {
  getBillingEntity,
  getBillingEntityDetails,
  getActiveBillingEntity
};
