import {
    CancelOutlined,
    EditOutlined,

} from "@mui/icons-material";
import { MenuItem } from "@mui/material";
import { StyledActionsMenu } from "../styles";
interface Props {
    anchorEl: HTMLElement | null;
    setAnchorEl: (anchorEl: HTMLElement | null) => void;
    handleExport: (type: string) => void;
    setType: (type: string) => void;

}

const ExportActions = (props: Props) => {
    const { anchorEl, setAnchorEl, handleExport, setType } = props;
    const handleExports = (type: string) => {
        setType(type)
        handleExport(type);
    };

    return (
        <>
            <StyledActionsMenu
                anchorEl={anchorEl}
                onClick={() => setAnchorEl(null)}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
            >
                <MenuItem onClick={() => handleExports('Type-A')} >
                    Based on the Line Item
                </MenuItem>
                <MenuItem onClick={() => handleExports('Type-B')}>
                    Based on the Tax Rate
                </MenuItem>
            </StyledActionsMenu>

        </>
    );
};

export default ExportActions