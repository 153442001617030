import converter from "number-to-words";

export const formateAmountWords = (word: string) => {
    const modified = word.replace(/,/g, '');//replace Commas ","
    const finalWord = modified.replace(/\b\w/g, char => char.toUpperCase());//replace First characterwirh Capital
    return finalWord;


};

export function formatAmountWithPointsWords(amount) {
    if (!amount || isNaN(amount)) {
        return "Invalid amount";
    }
    const [integerPart, decimalPart] = amount.toString().split('.');
    const integerWords = converter.toWords(Number(integerPart));
    const decimalWords = decimalPart
        ? converter.toWords(Number(decimalPart)) : ''
    return decimalWords
        ? `${formateAmountWords(integerWords)} and ${formateAmountWords(decimalWords) + (decimalWords ? " paise" : '')}  `
        : formateAmountWords(integerWords);
};