import { Grid, Typography, styled } from "@mui/material";
import { Box } from "@mui/system";
import { atomByViderLogo, atom_logo, logo } from "assets";
import { snack } from "components/toast";
import useQueryParams from "hooks/useQueryParams";
import { useContext, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { ResType } from "types";
import { handleError } from "utils/handleError";
import { getTitle } from "utils";
import { formattedDate } from "utils/formattedDate";
import { AddressDetail } from "views/billing/estimates/AddEstimate/BillingEntityDetails";
import { getAddress } from "views/billing/estimates/EditEstimate/BillingEntityDetails";
import SectionHeading from "views/billing/estimates/SectionHeading";
import { downloadInvoice } from "api/services/billing/invoices";
import { DownloadOutlined } from "@mui/icons-material";
import { Permissions } from "data/permissons";
import { placeOfSupplyStates } from "data/placeOfSupply";
import { showAddress } from "views/billing/estimates/AddEstimate/ClientDetails";
import { UserProfileContext } from "context/UserProfile";

export const StyledDownloadButton = styled("div")(() => ({
  width: "80px",
  height: "50px",
  zIndex: 100,
  cursor: "pointer",
  float: "right",
  fontSize: "10px"
}));

function BasicDetails({ result }) {
  const params = useParams();
  const { data: uData } = useContext(UserProfileContext);
  const [isdownloading, setIsdownloading] = useState(false);

  const { mutate } = useMutation(downloadInvoice, {
    onSuccess: (res: any) => {
      const arr = new Uint8Array(res.data?.data);
      const blob = new Blob([arr], { type: "application/pdf" });
      const pdf = window.URL.createObjectURL(blob);
      let link = document.createElement("a");
      link.href = pdf;
      link.download = "invoice.pdf";
      link.click();
      setIsdownloading(false);
    },
    onError: (err: any) => {
      snack.error(handleError(err));
    },
  });

  const handleDownload = () => {
    setIsdownloading(true);
    mutate({ id: params.invoiceId, userId: uData.id  });
  };

  const locationOfSupplyFromate = (location) => {
    let locationOfSupply = placeOfSupplyStates.find(
      (o: any) => o.split('-')[1] === location
    ) || "NA"
    return locationOfSupply
  }
  return (
    <Box >
      <Box textAlign='center'>
        <Typography color="#0D46A0" variant="h5" mb={2}>
          {result?.billingEntity?.hasGst ? "Tax Invoice" : "Invoice"}
        </Typography>
      </Box>
      <Grid container xs={12}>
        <Grid item xs={6} >
          <Box>
            <Grid container spacing={3} justifyContent="space-between">
              <Grid item xs={11.5}>

                <SectionHeading title="Billed By" color="#0D47A1" />
                <Box p={1}>
                  <AddressDetail
                    title="Trade Name"
                    value={result?.billingEntity?.tradeName}
                  />
                  <AddressDetail
                    title="Address"
                    value={getAddress(result?.billingEntity)}
                  />
                  <Typography variant="body2">
                    {result?.billingEntity?.mobileNumber && <AddressDetail
                      title="Mobile Number"
                      value={result?.billingEntity?.mobileNumber}
                    />}

                    {result?.billingEntity?.email && <AddressDetail
                      title="Email ID"
                      value={result?.billingEntity?.email}
                    />}
                    {result?.billingEntity?.hasGst &&
                      <AddressDetail
                        title="PAN"
                        value={result?.billingEntity?.gstNumber.slice(2, -3)}
                      />}
                    {!result?.billingEntity?.hasGst &&
                      <AddressDetail
                        title="PAN"
                        value={result?.billingEntity?.panNumber}
                      />}
                    {result?.billingEntity?.hasGst &&
                      <AddressDetail
                        title="GSTIN"
                        value={result?.billingEntity?.gstNumber}
                      />}
                    {result?.billingEntity?.hasGst &&
                      <AddressDetail
                        title="Location of Supplier"
                        value={locationOfSupplyFromate(result?.billingEntity?.locationOfSupply)}
                      />
                    }


                    {/* <AddressDetail
                title="Place Of Supply"
                value={result?.placeOfSupply}
              /> */}
                    {/* <Typography variant="body2">
            {result?.billingEntity?.buildingName},
            {result?.billingEntity?.street},
            {result?.billingEntity?.city},
            {result?.billingEntity?.state},
            {result?.billingEntity?.pincode}, */}
                    {/* 2/91/20, BP Raju Marg, Laxmi Cyber City, Whitefields, Kondapur,
              Telangana 500081 */}
                    {/* </Typography>
            {result?.billingEntity?.mobileNumber},
            {result?.billingEntity?.email}, */}
                    {/* 9947368386, Viderbusiness@gmail.com */}
                  </Typography>
                </Box>
              </Grid>

              {/* <Grid item xs={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Box maxWidth={400}>
          </Box>
          </Grid> */}

            </Grid>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box >
            <Grid container spacing={3}>
              <Grid item xs={11.5}>
                <SectionHeading title="Billed To" color="#0D47A1" />
                <Box p={2}>
                  <AddressDetail
                    title="Trade Name"
                    value={result?.client?.legalName || result?.client?.displayName}
                  />
                  {showAddress(result?.client?.address?.billingaddress) && (<AddressDetail
                    title="Address"
                    value={getAddress(result?.client?.address?.billingaddress)}
                  />)}
                  <AddressDetail
                    title="Mobile Number"
                    value={result?.client?.mobileNumber}
                  />
                  <AddressDetail
                    title="Email ID"
                    value={result?.client?.email}
                  />
                  {result?.client?.gstNumber && <AddressDetail
                    title="GSTIN"
                    value={result?.client?.gstNumber}
                  />}
                  {result?.billingEntity?.hasGst && <AddressDetail
                    title="Place of Supply"
                    value={result?.placeOfSupply}
                  />}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>

      </Grid>

    </Box>


  );
}

export default BasicDetails;