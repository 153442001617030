import {
  DownloadOutlined,
  PreviewOutlined,
} from "@mui/icons-material";
import { Menu, MenuItem } from "@mui/material";
import {
  downloadInvoice,
} from "api/services/billing/invoices";
import { snack } from "components/toast";
import { useContext, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  handleClientChange,
} from "redux/reducers/createReceiptSlice";
import { handleError } from "utils/handleError";
import { StyledActionsMenu } from "../styles";
import { InvoiceStatus } from "../types";
import { UserProfileContext } from "context/UserProfile";
interface Props {
  anchorEl: HTMLElement | null;
  setAnchorEl: (anchorEl: HTMLElement | null) => void;
  selected: any[];
  clearSelection: () => void;
}

const Actions = (props: Props) => {
  const { anchorEl, setAnchorEl, selected, clearSelection } = props;
  const navigate = useNavigate();
  const { data: uData } = useContext(UserProfileContext);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const { mutate } = useMutation(downloadInvoice, {
    onSuccess: (res: any) => {
      const arr = new Uint8Array(res.data?.data);
      const blob = new Blob([arr], { type: "application/pdf" });
      const pdf = window.URL.createObjectURL(blob);
      let link = document.createElement("a");
      link.href = pdf;
      link.download = `${selected[0]?.client?.tradeName || selected[0]?.client?.displayName} - ${selected[0]?.invoiceNumber}.pdf`;
      link.click();
    },
    onError: (err: any) => {
      snack.error(handleError(err));
    },
  });

  const handlePriview = () => {
    dispatch(handleClientChange({ client: selected[0].id }))
    navigate(`/billing/invoices/${selected[0]?.id}/preview`)

  };

  const handleDownload = () => {
    setIsLoading(true)
    mutate({ id: selected[0]?.id, userId: uData.id  });
  };

  return (
    <>
      <StyledActionsMenu
        anchorEl={anchorEl}
        onClick={() => setAnchorEl(null)}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
          <MenuItem
            disabled={
              selected.length !== 1 ||
              selected[0]?.status === InvoiceStatus.CANCELLED
            }
            onClick={() => handlePriview()}
          >
            <PreviewOutlined />
            Preview
          </MenuItem>

          <MenuItem
            onClick={handleDownload}
            disabled={
              selected.length !== 1 ||
              selected[0]?.status === InvoiceStatus.CANCELLED ||
              isLoading
            }
          >
            <DownloadOutlined />
            Download Invoice
          </MenuItem>
      </StyledActionsMenu>
    </>
  );
};

export default Actions