import countries from "data/countries";
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import moment, { duration } from "moment";

export const getTitle = (key: string) => {
  key = key || "";
  return key
    ?.split("_")
    .map((item) => item.charAt(0).toUpperCase() + item.slice(1))
    .join(" ");
};

export const capitalizeWords = (key: string) => {

  return key
    .split(' ') // Split the string into an array of words
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize the first letter of each word and make the rest lowercase
    .join(' '); // Join the words back into a single string
}

export const getFileSize = (size: number) => {
  if (size < 1024) {
    return `${size} Bytes`;
  }
  if (size < 1024 * 1024) {
    return `${(size / 1024).toFixed(2)} KB`;
  }
  if (size < 1024 * 1024 * 1024) {
    return `${(size / (1024 * 1024)).toFixed(2)} MB`;
  }
  if (size < 1024 * 1024 * 1024 * 1024) {
    return `${(size / (1024 * 1024 * 1024)).toFixed(2)} GB`;
  }
  return 0;
};

export const getHoursOptions = () => {
  return Array.from(Array(24).keys()).map((_, index) => {
    return {
      label: index <= 9 ? `0${index}` : index?.toString(),
      value: index <= 9 ? `0${index}` : index?.toString(),
    };
  });
};

export const getMinutesOptions = () => {
  return Array.from(Array(60).keys()).map((_, index) => {
    return {
      label: index <= 9 ? `0${index}` : index?.toString(),
      value: index <= 9 ? `0${index}` : index?.toString(),
    };
  });
};

export const formattedDate = (date: string) => {
  return moment(date).format("YYYY-MM-DD");
};

export const formattedDatetime = (date: string) => {
  return moment(date).format("YYYY-MM-DD HH:mm A");
};

export const getTotalLogHoursDuration = (logs: any[]) => {
  let total = logs.reduce((acc, cur) => {
    return acc + +cur.duration;
  }, 0);

  return moment.utc(total).format("HH:mm");
};

export const totalLogHours = (duration) => {
  return moment.utc(duration).format("HH:mm");
};

export const covertToKb = (size: number, type: "KB" | "MB" | "GB") => {
  if (type === "KB") {
    return size;
  }
  if (type === "MB") {
    return size * 1024;
  }
  return 0;
};

export const fileSizeInKb = (size: number) => {
  return size / 1024;
};

export const getExpenseType = (key: string) => {
  if (key === "PURE_AGENT") return "Pure Agent";
  else if (key === "ADDITIONAL") return "Additional Charges";
  else return "---";
};

export const getFinancialYears = () => {
  return Array.from(Array(50).keys()).map((_, index) => {
    return `${2000 + index}-${2000 + index + 1}`;
  });
};

export const getFieldSize = (fieldSize: "SMALL" | "MEDIUM" | "LARGE") => {
  switch (fieldSize) {
    case "SMALL":
      return "50%";
    case "MEDIUM":
      return "75%";
    case "LARGE":
      return "100%";
    default:
      return "100%";
  }
};

export const getTitles = (key: string, toCamelCase: boolean = false) => {
  key = key || "";
  key
    ?.split("_")
    .map((item) => item.charAt(0).toUpperCase() + item.slice(1))
    .join(" ");

  return key
    .split(' ') // Split the string into an array of words
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize the first letter of each word and make the rest lowercase
    .join(' '); // J
};


export function getCurrentFormattedDateTime() {
  const currentDate = new Date();
  const day = String(currentDate.getDate()).padStart(2, '0');
  const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // January is 0!
  const year = currentDate.getFullYear();
  const hours = String(currentDate.getHours()).padStart(2, '0');
  const minutes = String(currentDate.getMinutes()).padStart(2, '0');

  // Format the date as dd-mm-yyyy and time as HH:MM
  const formattedDate = `${day}-${month}-${year}`;
  const formattedTime = `${hours}-${minutes}`; // Colon (:) separator for time

  return `${formattedDate} ${formattedTime}`;
}

export function mobileWithCountry(phone: number, country: any): boolean {
  const countryCode = countries.find((c) => c.label === country);
  if (!countryCode) {
    ;
    return false;
  }

  const phoneNumber = parsePhoneNumberFromString(
    `+${countryCode.phone}${phone}`,
    country
  );

  return phoneNumber?.isValid() ? false : true;
}

