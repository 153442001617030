import { Add } from "@mui/icons-material";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import BrowserUpdatedOutlinedIcon from "@mui/icons-material/BrowserUpdatedOutlined";
import { Box, Button, Checkbox, FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Select, styled, TextField, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material";
import SearchContainer from "components/SearchContainer";
import { snack } from "components/toast";
import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { handleError } from "utils/handleError";
import ValidateAccess from "components/ValidateAccess";
import { Permissions } from "data/permissons";
import Actions from "./Actions";
import { exportProformaInvoices } from "api/services/billing/proforma-invoice";
import DateRange from "./DateRange";
import { getBillingEntity } from "api/services/billingEntity";
import { ResType } from "types";
import ExportActions from "../invoices/ExportActions";
import { getCurrentFormattedDateTime } from "utils";



const StyledBox = styled(Box) <{ isMobile: boolean }>`
  display: ${({ isMobile }) => (isMobile ? "block" : "flex")};
  justify-content: ${({ isMobile }) => (isMobile ? "initial" : "space-between")};
  align-items: center;
  gap: ${({ isMobile }) => (isMobile ? "1rem" : "initial")};
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 16px;
  ${({ isMobile }) => isMobile && `
    // margin-left: 16px;  
    // margin-top: 16px;
  `}
`;

const StyledButtonContainer = styled(Box) <{ isMobile: boolean }>`
  display: ${({ isMobile }) => (isMobile ? "block" : "flex")};
  gap: 8px;
  ${({ isMobile }) => isMobile && `
    button {
      margin-left: 16px;
      margin-top: 8px;
    }
  `}
`;

interface IProps {
    selected: any[];
    search: string;
    setSearch: (v: string) => void;
    clearSelection: () => void;
    filters: any;
    setFilters: (V: any) => void;
    setPage: (v: number) => void;
    dates: any;
    setDates: (v: any) => void;
    sort: any;
}

function ProFormaHeader(props: IProps) {
    const { selected, search, setSearch, clearSelection, filters, setFilters, dates, setDates, sort, setPage } = props;
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [anchorEl2, setAnchorEl2] = useState<null | HTMLElement>(null);
    const [type, setType] = useState('');
    const clientId = localStorage.getItem("clientId");
    const [filterfields, setFilterfields] = useState([
        { type: 'multiSelect', label: 'Billing Entity', name: 'billingEntity', options: [], optionName: 'tradeName' }
    ]);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const { data: clients, isLoading: billingLoading }: ResType = useQuery(
        ["billing-entites", {clientId}],
        getBillingEntity,
        {
            onSuccess: (res: any) => {
                const filterfieldsstate: any = JSON.parse(JSON.stringify(filterfields));
                filterfieldsstate.forEach((field: any) => {
                    if (field.type == 'multiSelect') {
                        field.options = res?.data;
                    }
                });
                setFilterfields(filterfieldsstate);
            }
        }
    );

    const { mutate, isLoading } = useMutation(exportProformaInvoices, {
        onSuccess: (res: any) => {
            const arr = new Uint8Array(res.data?.file?.data);
            const blob = new Blob([arr], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            const pdf = window.URL.createObjectURL(blob);
            let link = document.createElement("a");
            const formattedDateTime = getCurrentFormattedDateTime();
            link.href = pdf;
            link.download = (res.data?.type == 'Type-A') ? `ATOM - Proforma Invoices based on line Item ${formattedDateTime}` : `ATOM - Proforma Invoices based on Tax Rate ${formattedDateTime}`;
            link.click();
            snack.success("Proforma Invoice(s) List Exported to Excel");

        },
        onError: (err: any) => {
            snack.error(handleError(err));
        },
    });

    const handleExport = (type: string) => {
        mutate({ data: { ...filters, search, sort, ...dates, type } });
    };

    const handleCategoryChange = (e: any) => {
        setFilters({
            ...filters,
            status: e.target.value
        });
        setPage(0);
    };

    const status = [
        { id: "CREATED", name: "Created" },
        { id: 'CONVERTED', name: "Converted" },
        { id: "CANCELLED", name: 'Cancelled' },
        { id: "CLOSED", name: "Closed" },
    ]

    return (
        <>
            <StyledBox isMobile={isMobile}>
                <SearchContainer debounced value={search} placeHolder="Search by Invoice # | Client Name" onChange={setSearch} minWidth={isMobile ? "200px" : "250px"} />
                <StyledButtonContainer isMobile={isMobile}>
                    <Box sx={{ marginX: "5px" }} >
                        <DateRange dates={dates} setDates={setDates} setPage={setPage} />
                    </Box>
                    <Box width={150}>
                        {filterfields?.map((field: any, index) => (
                            <Grid key={index} item xs={12} sm={3} md={3} lg={3}>

                                {(field.type === "multiSelect" && field.label === 'Billing Entity') && (
                                    <Grid item xs={12}>
                                        <FormControl fullWidth size="small" sx={{ flex: 1, maxWidth: "41vw" }}>
                                            <InputLabel id="demo-multiple-checkbox-label">{field?.label}</InputLabel>
                                            <Select
                                                multiple
                                                value={filters.billingEntity}
                                                input={<OutlinedInput label={field.label} />}
                                                renderValue={(selected) => {
                                                    return field.options
                                                        ?.filter((item: any) => selected.includes(item?.id))
                                                        .map((item: any) => item.tradeName)
                                                        .join(", ");
                                                }}
                                                MenuProps={{
                                                    PaperProps: {
                                                        sx: {
                                                            maxHeight: "300px",
                                                        },
                                                    },
                                                }}
                                                sx={{ maxWidth: "41vw" }}
                                            >
                                                <Box display={"flex"} alignItems={"center"} m={1}>
                                                    <Checkbox
                                                        checked={field?.options?.length === filters?.billingEntity?.length}
                                                        onChange={(e) => {
                                                            if (e.target.checked) {
                                                                setFilters({
                                                                    ...filters,
                                                                    billingEntity: field?.options?.map((item: any) => item?.id),
                                                                });
                                                                setPage(0)
                                                            } else {
                                                                setFilters({ ...filters, billingEntity: [] });
                                                                setPage(0);
                                                            }
                                                        }}
                                                        sx={{ marginRight: "10px" }}
                                                    />
                                                    <Typography> Select All </Typography>
                                                </Box>
                                                {field?.options?.map((item: any) => (
                                                    <Box display={"flex"} key={item?.id || index} alignItems={"center"} m={1}>
                                                        <Checkbox
                                                            checked={filters.billingEntity.indexOf(item?.id) > -1}
                                                            onChange={(e) => {
                                                                if (e.target.checked) {
                                                                    if (filters.billingEntity.includes(item.id)) {
                                                                        const billingList = filters.billingEntity.filter(
                                                                            (eachitem) => eachitem !== item.id
                                                                        );
                                                                        setFilters({
                                                                            ...filters,
                                                                            billingEntity: [...billingList],
                                                                        });
                                                                        setPage(0);
                                                                    } else {
                                                                        setFilters({
                                                                            ...filters,
                                                                            billingEntity: [...filters.billingEntity, item.id],
                                                                        });
                                                                        setPage(0);
                                                                    }
                                                                } else {
                                                                    const billingList = filters.billingEntity.filter((eachitem) => eachitem !== item.id);
                                                                    setFilters({
                                                                        ...filters,
                                                                        billingEntity: [...billingList],
                                                                    });
                                                                    setPage(0);
                                                                }
                                                            }}
                                                            sx={{ marginRight: "10px" }}
                                                        />
                                                        <Typography>{item?.tradeName}</Typography>
                                                    </Box>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                )}

                            </Grid>
                        ))}
                    </Box>
                    <Box display="flex">
                        <TextField
                            label="Invoice Status"
                            value={filters.status}
                            sx={{ minWidth: 150, mr: 1, width: isMobile ? "100%" : 120, mb: isMobile ? 1 : 0 }}
                            onChange={handleCategoryChange}
                            size="small"
                            select
                        >
                            <MenuItem key={""} value={""}>
                                Select
                            </MenuItem>
                            {status.map((option: any, index: any) => (
                                <MenuItem key={index} value={option.id}>
                                    {option.name}
                                </MenuItem>
                            ))}
                        </TextField>
                        <ValidateAccess name={Permissions.EXPORT_PROFORMA_INVOICES_DOWNLOAD}>
                            <Tooltip title="Export to Excel" arrow>
                                <Button
                                    sx={{ pl: 0 }}
                                    onClick={(e) => setAnchorEl2(e.currentTarget)}
                                    endIcon={<BrowserUpdatedOutlinedIcon fontSize="small" />}
                                    color="primary"
                                    variant="outlined"
                                    disabled={isLoading}
                                >
                                </Button>
                            </Tooltip>
                        </ValidateAccess>

                        <Button
                            endIcon={<ArrowDropDownOutlinedIcon />}
                            color="primary"
                            variant="outlined"
                            onClick={(e) => setAnchorEl(e.currentTarget)}
                            sx={{ ml: 1 }}
                        >
                            Actions
                        </Button>
                    </Box>
                    <ValidateAccess name={Permissions.CREATE_PROFORMA_INVOICE}>
                        <Button
                            onClick={() => navigate("/billing/proforma/add")}
                            variant="outlined"
                            color="secondary"
                            startIcon={<Add />}
                        >
                            Proforma Invoice
                        </Button>
                    </ValidateAccess>
                </StyledButtonContainer>
            </StyledBox>
            <Actions
                clearSelection={clearSelection}
                selected={selected}
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
            />
            <ExportActions
                anchorEl={anchorEl2}
                setAnchorEl={setAnchorEl2}
                handleExport={handleExport}
                setType={setType}
            />
        </>
    );
}

export default ProFormaHeader;
