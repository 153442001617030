export interface IMenuItem {
  title: string;
  path: string;
  icon?: string;
  children?: Array<IMenuItem>;
}

export const invoicingMenu: Array<IMenuItem> = [
  {
    title: "Clients / Clients Group",
    path: "/billing/clients",
  },
  {
    title: "Expenses",
    path: '/billing/expenditure'
  },
  {
    title: "Proforma Invoices",
    path: "/billing/proforma"
  },
  {
    title: "Invoices",
    path: "/billing/invoices",
  },
  {
    title: 'GST Payable',
    path: '/billing/gst'
  },
  {
    title: "Receipts",
    path: "/billing/receipts",
  },
  {
    title: "TDS Receivable",
    path: "/billing/tds",
  },
];
